
import { defineComponent, computed, ref } from 'vue'
import MasterTable from '@/components/master/MasterTable.vue'
import { useVehicleTypeGroup } from '@/composable/useVehicleTypeGroup'
import { useVehicleTypeGroupPeriod } from '@/composable/useVehicleTypeGroupPeriod'
import {
  required as requiredRule,
  booleanTrueRequired,
  requiredNotNegative,
} from '@/common/formValidationRules'
import { v4 as uuidv4 } from 'uuid'
import { VehicleTypeGroupPeriod } from '@/types/vehicle-type-group-period'
import AppFieldDate from '@/components/AppFieldDate.vue'
import { addDays, addMonths, format } from 'date-fns'

export default defineComponent({
  name: 'MasterVehicleTypeGroupPeriods',

  components: {
    MasterTable,
    AppFieldDate,
  },

  setup() {
    const { loading: loadingStateVehicleTypeGroups, data: vehicleTypeGroups } =
      useVehicleTypeGroup(true)

    const {
      loading: loadingStateVehicleTypeGroupPeriods,
      data: vehicleTypeGroupPeriods,
      add,
      update,
      remove,
    } = useVehicleTypeGroupPeriod(true)

    function createFormModel(): Partial<VehicleTypeGroupPeriod> {
      return {
        uuid: uuidv4(),
        vehicleTypeGroupUuid: '',
        periodStart: format(new Date(), 'yyyy-MM-dd'),
        periodEnd: format(new Date(), 'yyyy-MM-dd'),
        customerAvailabilityLevel1: 0,
        customerAvailabilityLevel2: 0,
        customerAvailabilityLevel3: 0,
        customerAvailabilityLevel4: 0,
        comment: '',
      }
    }
    const model = ref<Partial<VehicleTypeGroupPeriod>>(createFormModel())

    function resetFormModel() {
      model.value = createFormModel()
    }

    const validPeriodRange = computed(() => {
      const start = model.value.periodStart as string
      const end = model.value.periodEnd as string
      return +new Date(start) <= +new Date(end)
    })

    const columns = computed(() => [
      {
        name: 'vehicleTypeGroupUuid',
        label: 'Fordonstyp grupp',
        align: 'left',
        field: 'vehicleTypeGroupUuid',
        format: (vehicleTypeGroupUuid: string) =>
          vehicleTypeGroups.value.find((x) => x.uuid === vehicleTypeGroupUuid)
            ?.name || '',
        sortable: true,
      },
      {
        name: 'periodStart',
        label: 'Periodstart',
        align: 'left',
        field: 'periodStart',
        format: (date: string) => format(new Date(date), 'yyyy-MM-dd'),
        sortable: true,
      },
      {
        name: 'periodEnd',
        label: 'Periodslut',
        align: 'left',
        field: 'periodEnd',
        format: (date: string) => format(new Date(date), 'yyyy-MM-dd'),
        sortable: true,
      },
      {
        name: 'customerAvailabilityLevel1',
        label: 'Kundtillgänglighet nivå 1',
        align: 'left',
        field: 'customerAvailabilityLevel1',
        sortable: true,
      },
      {
        name: 'customerAvailabilityLevel2',
        label: 'Kundtillgänglighet nivå 2',
        align: 'left',
        field: 'customerAvailabilityLevel2',
        sortable: true,
      },
      {
        name: 'customerAvailabilityLevel3',
        label: 'Kundtillgänglighet nivå 3',
        align: 'left',
        field: 'customerAvailabilityLevel3',
        sortable: true,
      },
      {
        name: 'customerAvailabilityLevel4',
        label: 'Kundtillgänglighet nivå 4',
        align: 'left',
        field: 'customerAvailabilityLevel4',
        sortable: true,
      },
      {
        name: 'comment',
        label: 'Kommentar',
        align: 'left',
        field: 'comment',
        sortable: true,
      },
    ])

    const loading = computed(() => {
      return {
        ...loadingStateVehicleTypeGroups.value,
        ...loadingStateVehicleTypeGroupPeriods.value,
      }
    })

    const exportData = computed(() => {
      return vehicleTypeGroupPeriods.value.map((vehicleTypeGroup) => {
        return {
          'Fordonstyp grupp': vehicleTypeGroup.vehicleTypeGroupUuid,
          Periodstart: vehicleTypeGroup.periodStart,
          Periodend: vehicleTypeGroup.periodEnd,
          'Kundtillgänglighet nivå 1':
            vehicleTypeGroup.customerAvailabilityLevel1,
          'Kundtillgänglighet nivå 2':
            vehicleTypeGroup.customerAvailabilityLevel2,
          'Kundtillgänglighet nivå 3':
            vehicleTypeGroup.customerAvailabilityLevel3,
          'Kundtillgänglighet nivå 4':
            vehicleTypeGroup.customerAvailabilityLevel4,
          Kommentar: vehicleTypeGroup.comment,
        }
      })
    })

    const onCopyFormModel = (row: VehicleTypeGroupPeriod) => {
      model.value = row
      model.value.uuid = uuidv4()
      model.value.periodStart = format(
        addDays(new Date(row.periodEnd), 1),
        'yyyy-MM-dd'
      )
      model.value.periodEnd = format(
        addMonths(new Date(model.value.periodStart), 1),
        'yyyy-MM-dd'
      )
    }

    return {
      columns,
      model,
      requiredRule,
      requiredNotNegative,
      booleanTrueRequired,
      vehicleTypeGroups,
      vehicleTypeGroupPeriods,
      loadingStateVehicleTypeGroups,
      add,
      remove,
      update,
      loading,
      resetFormModel,
      exportData,
      validPeriodRange,
      onCopyFormModel,
    }
  },
})
