import { Ref, ref, ComputedRef, computed } from 'vue'
import { useLoading, Loading } from '@/composable/useLoading'
import { getVehicleTypeGroupsPeriod } from '@/api/vehicle-type-group-period/getVehicleTypeGroupsPeriod'
import { VehicleTypeGroupPeriod } from '@/types/vehicle-type-group-period'
import { deleteVehicleTypeGroupPeriod } from '@/api/vehicle-type-group-period/deleteVehicleTypeGroupPeriod'
import { createVehicleTypeGroupPeriod } from '@/api/vehicle-type-group-period/createVehicleTypeGroupPeriod'
import { updateVehicleTypeGroupPeriod } from '@/api/vehicle-type-group-period/updateVehicleTypeGroupPeriod'

interface UseVehicleTypeGroupPeriod {
  data: Ref<VehicleTypeGroupPeriod[]>
  fetchAll: () => Promise<VehicleTypeGroupPeriod[]>
  remove: (uuid: string) => Promise<null>
  add: (body: VehicleTypeGroupPeriod) => Promise<VehicleTypeGroupPeriod>
  update: (body: VehicleTypeGroupPeriod) => Promise<VehicleTypeGroupPeriod>
  loading: ComputedRef<Loading>
}

export function useVehicleTypeGroupPeriod(
  initFetchAll?: boolean
): UseVehicleTypeGroupPeriod {
  const data = ref<VehicleTypeGroupPeriod[]>([])
  const { state } = useLoading()

  async function fetchAll() {
    state.getAll = true
    return new Promise<VehicleTypeGroupPeriod[]>((resolve, reject) => {
      getVehicleTypeGroupsPeriod()
        .then(({ data: vehicleTypeGroups }) => {
          data.value = vehicleTypeGroups
          resolve(vehicleTypeGroups)
        })
        .catch(reject)
        .finally(() => {
          state.getAll = false
        })
    })
  }

  async function remove(uuid: string) {
    state.delete = true
    return new Promise<null>((resolve, reject) => {
      deleteVehicleTypeGroupPeriod(uuid)
        .then(() => {
          data.value = data.value.filter((item) => item.uuid !== uuid)
          resolve(null)
        })
        .catch(reject)
        .finally(() => {
          state.delete = false
        })
    })
  }

  async function add(body: VehicleTypeGroupPeriod) {
    state.create = true
    return new Promise<VehicleTypeGroupPeriod>((resolve, reject) => {
      createVehicleTypeGroupPeriod(body)
        .then(({ data: vehicleTypeGroup }) => {
          data.value.push(vehicleTypeGroup)
          resolve(vehicleTypeGroup)
        })
        .catch(reject)
        .finally(() => {
          state.create = false
        })
    })
  }

  async function update(body: VehicleTypeGroupPeriod) {
    state.update = true
    return new Promise<VehicleTypeGroupPeriod>((resolve, reject) => {
      updateVehicleTypeGroupPeriod(body)
        .then(({ data: vehicleTypeGroup }) => {
          data.value = data.value.map((item) => {
            if (item.uuid === vehicleTypeGroup.uuid) {
              return {
                ...item,
                ...vehicleTypeGroup,
              }
            }

            return item
          })
          resolve(vehicleTypeGroup)
        })
        .catch(reject)
        .finally(() => {
          state.update = false
        })
    })
  }

  if (initFetchAll) {
    fetchAll()
  }

  return {
    data,
    fetchAll,
    add,
    remove,
    update,
    loading: computed(() => state),
  }
}
